@media (max-width: 460px) {
    .persona-form {
        padding-left: 2.8rem !important;
    }
}

@media (min-width: 460.1px) {
    .persona-form {
        padding-left: 3rem !important;
    }
}


/* ----------------- LABELS ----------------- */

.label-link {
    color: #51cbce !important;
    font-size: 14px !important;
    font-family: "Barlow", Montserrat, Arial, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    cursor: pointer !important;
    user-select: none;
    margin-top: 0.4rem;
}

.label-link:hover {
    text-decoration: underline currentcolor !important;
    cursor: pointer !important;
}

.p-title-view {
    font-size: 1.1rem !important;
    margin: 0 !important;
    font-family: "Barlow", Montserrat, Arial, sans-serif !important;
}

.label-detail-view {
    font-size: 0.9rem !important;
    color: #9A9A9A !important;
    font-family: "Barlow", Montserrat, Arial, sans-serif !important;
}


/* ----------------- CEDULA-OWNERSHIP ----------------- */

@media (max-width: 1199.9px) {
    .left-padding {
        margin-left: 25% !important;
    }
}

@media (max-width: 992px) {
    .left-padding {
        margin-left: 25% !important;
    }
}

@media (max-width: 768px) {
    .left-padding {
        margin-left: 18% !important;
    }
}

@media (max-width: 576px) {
    .left-padding {
        margin-left: 0% !important;
    }
}

@media (min-width: 1200px) and (max-width: 1260px) {
    .font-mini {
        width: 4rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0px !important;
    }
    .lender-field-ownership {
        min-width: 5rem !important;
        width: 5rem !important;
    }
}


/* ----------------- BOTONES ----------------- */

.button-alapar-success {
    background-color: #1d575f;
    text-transform: none;
    font-weight: normal;
    font-size: 16px;
    padding: 6px 12px;
}

.button-alapar-success:hover {
    background-color: #31818b !important;
}

.button-alapar-danger {
    background-color: #ae0101;
    text-transform: none;
    font-weight: normal;
    font-size: 16px;
    padding: 6px 12px;
}

.button-alapar-danger:hover {
    background-color: #820404 !important;
}

.table-alapar-title-default {
    color: #12383c;
    font-size: 14px !important;
}

.table-alapar-text-default {
    font-size: 14px !important;
}

.button-alapar-cancel {
    text-transform: none;
    font-weight: normal;
    font-size: 16px;
    padding: 5px 11px;
    border-color: gray !important;
    color: gray !important;
    border: 1px solid !important;
}

.button-alapar-cancel:hover {
    background-color: rgb(120, 120, 120) !important;
    border-color: rgb(86, 85, 85) !important;
    color: white !important;
    border: 1px solid transparent !important;
}

.button-alapar-warning {
    background-color: #f4b907;
    text-transform: none;
    font-weight: normal;
    font-size: 16px;
    padding: 6px 12px;
}

.button-alapar-warning:hover {
    background-color: #f0ad4e !important;
}

.button-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.button-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.navbar-brand {
    text-transform: none !important;
}

.graphCardHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 5px;
    border-bottom: 1px solid #e4e9ec;
    position: relative;
    height: 50px
}

.emphasizedHeader {
    padding: 0;
    margin: 0;
    font-size: 0.9rem;
    color: #81878C;
    font-weight: 700;
}

.graphOptionsMenuContainer {
    flex: 1;
    background-color: white;
    display: flex;
    justify-content: flex-end;
}

.graphOptionsMenuButton {
    display: flex;
    align-items: center;
    background-color: #f2f3f6;
    height: 30px;
    padding: 4px 10px;
    padding-top: 1rem;
    cursor: pointer;
    border: 1px solid #e4e9ec;
    border-style: none;
    border-bottom-style: solid;
    box-shadow: 0 2px 0 #e4e9ec;
}

.graphOptionsMenuButton:hover {
    background-color: #fcfcfc
}

.graphOptionsMenuIcon {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 7px;
    margin-top: -0.6rem;
}

.chart {
    width: 100%;
    max-height: 460px;
}

.graphLegendVisualRepresentation {
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.graphLegend {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}

.graphLegendsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.line-y-axis line {
    opacity: 0.2;
    stroke: rgb(34, 166, 245);
    stroke-dasharray: 5px, 5px;
}

.line-y-axis path {
    display: none;
}

.line-y-axis text {
    fill: rgb(113, 135, 124);
}

.line-x-axis line {
    display: none;
}

.line-x-axis path {
    display: none;
}

.line-x-axis text {
    fill: rgb(113, 135, 124);
}

.buttonActionStyle {
    margin-top: 0rem;
    text-align: left;
    width: 100%;
    background-color: white;
}

.buttonActionStyle:hover {
    background-color: white !important;
}

.buttonActionStyle:focus {
    background-color: white !important;
}

.dataOption {
    display: flex;
    flex-direction: row;
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
    width: 100%;
}

.dataOptionIconContainer {
    color: rgb(129, 135, 140);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border: 1px solid #dcdcdc;
    border-radius: 50%;
}

.dataOptionTextHolder {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    color: rgb(0, 58, 111);
}

.dataOptionTextHolderExtra {
    color: rgb(129, 135, 140);
    margin-top: -0.2rem;
}